import moment from "moment";

export const formatDate = date =>
  moment(date)
    .utc()
    .format("DD/MM/YYYY");

export const getDays = (dateStart, dateEnd) => {
  let startDate = moment(dateStart);
  let endDate = moment(dateEnd);

  let startYear = parseInt(startDate.utc().format("YYYY"));
  let endYear = parseInt(endDate.utc().format("YYYY"));

  let startMonth = parseInt(startDate.utc().format("MM"));
  let endMonth = parseInt(endDate.utc().format("MM"));

  let startDay = parseInt(startDate.utc().format("DD"));
  let endDay = parseInt(endDate.utc().format("DD"));

  return (
    (endYear - startYear) * 360 +
    (endMonth - startMonth) * 30 +
    (endDay - startDay) +
    1
  );
};

export const getInterestValue = (interest_value, days) => {
  return (interest_value / 30) * days;
};
